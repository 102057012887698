/** @jsx jsx */
import { jsx } from "theme-ui"
// import { graphql } from "gatsby"
import { Link } from "gatsby"
import { keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { Button, Card, Image, Text } from "@theme-ui/components"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import Section from "../components/section"

const Thing = styled.div`
  color: blue;
  #main-area,
  .something {
    margin-top: 0vh;
  }
`
const Jumbotron = styled.div`
  background: #3d7aea;
  background-size: cover;
  color: #fff;
  position: relative;
  min-height: 100vh;
  z-index: 9999;

  h1 {
    position: relative;
    z-index: 1;
    font-size: 5em;
    margin-right: 0px;
    margin-left: 0px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    :hover {
      transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1);
      will-change: transform;
    }
  }

  p {
    font-family: serif;
    font-size: 30px;
    font-style: italic;
    margin: 0 0 30px;
  }
  button {
    display: inline-block;
    border: 2px solid #fff;
    background: transparent;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    transition: 0.3s;
    border-radius: 0;
  }
  & button :hover {
    border: 2px solid #000000;
    background-color: #000000;
  }
  @media (max-width: 849px) {
    h1 {
      font-size: 2.5em;
      word-wrap: break-word;
      text-align: left;
    }
  }
`

// const Company = "Turbine Studio"
// const Headline = "We transform your ideas into a digital experience"
const Headline = "LatestBlitz.com"
const HeadlineSecondarySlogan = ""

export default props => (
  <Thing>
    <Layout sx={{ bg: "#3d7aec", paddingTop: "50px" }} css={{}}>
      <Jumbotron>
        <div
          sx={{
            maxWidth: 1920,
            mx: "auto",
            px: 1,
            py: [1, 2],
            color: "background",
          }}
        >
          <div
            sx={{
              maxWidth: 960,
              mx: "auto",
              px: 2,
              py: [3, 6],
              color: "background",
            }}
          >
            <h1
              sx={{
                color: "#000000",
                variant: "styles.type.heading",
              }}
              css={{}}
            >
              {Headline}
            </h1>
            <h3 sx={{ textAlign: "center", color: "#ffffff" }}>
              {HeadlineSecondarySlogan}
            </h3>
          </div>
        </div>
      </Jumbotron>

      <Section
        background="#FFFFFF"
        color="#19227D"
        padding="3rem"
        sx={{ textAlign: "center" }}
      >
        <Fade top cascade delay={150} duration={1000}>
          <h3>Coming soon!</h3>
        </Fade>
      </Section>
    </Layout>
  </Thing>
)
